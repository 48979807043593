/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link, graphql } from "gatsby"
import { Fragment } from "react"
import _ from "lodash"

import SEO from "../components/Seo"
const language = process.env.GATSBY_LANGUAGE

const CookiePolicy = () => (
  <Fragment>
    <SEO title="Cookie Policy" />
    <div sx={{ variant: "policy" }}>
      <Styled.h1>Cookie Policy</Styled.h1>
      <section>
        <h3>
          Cookie Policy of <Link to="/">{language}StudyGuide.com</Link>
        </h3>
        <p>
          Cookies consist of portions of code installed in the browser that
          assist the Owner in providing the Service according to the purposes
          described. Some of the purposes for which Cookies are installed may
          also require the User's consent.
        </p>
        <p>
          Where the installation of Cookies is based on consent, such consent
          can be freely withdrawn at any time following the instructions
          provided in this document.
        </p>
      </section>
      <section>
        <h3>Third Party Cookies</h3>
        <p>
          Some of the services listed below collect statistics in an anonymized
          and aggregated form and may not require the consent of the User or may
          be managed directly by the Owner - depending on how they are described
          - without the help of third parties.
        </p>
        <p>
          If any third party operated services are listed among the tools below,
          these may be used to track Users’ browsing habits – in addition to the
          information specified herein and without the Owner’s knowledge. Please
          refer to the privacy policy of the listed services for detailed
          information.
        </p>
        <div>
          <h4>Analytics</h4>
          <p>
            The services contained in this section enable the Owner to monitor
            and analyze web traffic and can be used to keep track of User
            behavior.
          </p>
          <h5>
            Display Advertising extension for Google Analytics (Google Inc.)
          </h5>
          <p>
            Google Analytics on this Application might use Google's
            Interest-based advertising, 3rd-party audience data and information
            from the DoubleClick Cookie to extend analytics with demographics,
            interests and ads interaction data.
          </p>
          <p>
            <strong>Personal Data collected:</strong> Cookies and Usage Data.
          </p>
          <p>
            <strong>Place of processing:</strong> United States –{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/intl/policies/privacy/"
            >
              Privacy Policy
            </a>{" "}
            –{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://tools.google.com/dlpage/gaoptout"
            >
              Opt Out
            </a>
            . Privacy Shield participant.
          </p>
          <h5>Google Analytics with anonymized IP (Google Inc.)</h5>
          <p>
            Google Analytics is a web analysis service provided by Google Inc.
            (“Google”). Google utilizes the Data collected to track and examine
            the use of this Application, to prepare reports on its activities
            and share them with other Google services. Google may use the Data
            collected to contextualize and personalize the ads of its own
            advertising network.
          </p>
          <p>
            This integration of Google Analytics anonymizes your IP address. It
            works by shortening Users' IP addresses within member states of the
            European Union or in other contracting states to the Agreement on
            the European Economic Area. Only in exceptional cases will the
            complete IP address be sent to a Google server and shortened within
            the US.
          </p>
          <p>
            <strong>Personal Data collected:</strong> Cookies and Usage Data.
          </p>
          <p>
            <strong>Place of processing:</strong> United States –{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.google.com/intl/policies/privacy/"
            >
              Privacy Policy
            </a>
            –{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://tools.google.com/dlpage/gaoptout"
            >
              Opt Out
            </a>
            . Privacy Shield participant.
          </p>
        </div>
        <div>
          <h4>Remarketing and Behavioral Targeting</h4>
          <p>
            This type of service allows this Application and its partners to
            inform, optimize and serve advertising based on past use of this
            Application by the User. This activity is performed by tracking
            Usage Data and by using Cookies, information that is transferred to
            the partners that manage the remarketing and behavioral targeting
            activity.
          </p>
          <h5>
            Remarketing through Google Analytics for Display Advertising (Google
            Inc.)
          </h5>
          <p>
            Google Analytics for Display Advertising is a remarketing and
            behavioral targeting service provided by Google Inc. that connects
            the tracking activity performed by Google Analytics and its Cookies
            with the Adwords advertising network and the Doubleclick Cookie.
          </p>
          <p>
            <strong>Personal Data collected:</strong> Cookies and Usage Data.
          </p>
          <p>
            <strong>Place of processing:</strong> United States –{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.google.com/intl/policies/privacy/"
            >
              Privacy Policy
            </a>{" "}
            –{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://tools.google.com/dlpage/gaoptout"
            >
              Opt Out
            </a>
            . Privacy Shield participant.
          </p>
        </div>
      </section>
      <section>
        <h3>Owner and Data Controller</h3>
        <p>{language} Study Guide</p>
        <p>
          <b>Owner contact email:</b>{" "}
          <a href="mailto:privacy@languageguide.app">
            privacy@languageguide.app
          </a>
        </p>
        <p>
          Since the installation of third-party Cookies and other tracking
          systems through the services used within this Application cannot be
          technically controlled by the Owner, any specific references to
          Cookies and tracking systems installed by third parties are to be
          considered indicative. In order to obtain complete information, the
          User is kindly requested to consult the privacy policy for the
          respective third-party services listed in this document.
        </p>
        <p>
          Given the objective complexity surrounding the identification of
          technologies based on Cookies, Users are encouraged to contact the
          Owner should they wish to receive any further information on the use
          of Cookies by this Application.
        </p>
        <h5>Legal Information</h5>
        <p>
          This privacy statement has been prepared based on provisions of
          multiple legislations, including Art. 13/14 of Regulation (EU)
          2016/679 (General Data Protection Regulation).
        </p>
        <p>
          This privacy policy relates solely to this Application, if not stated
          otherwise within this document.
        </p>
        <p className="updated">Last Updated: September 9, 2019</p>
      </section>
    </div>
  </Fragment>
)

export default CookiePolicy
